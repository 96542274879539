<template>
  <scroller>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <template v-if="element !== null">
      <h1 class="text-primary pb-50 mt-2">
        {{ $t('pages.shipment.now.name') }}
      </h1>
      <h4> {{ element.emoji }} {{ element.name }}</h4>

      <div class="mt-3 mb-3">
        <div class="mb-2 pb-25">
          <h7>📦 &nbsp; <span class="text-primary pr-25">{{ element.quantity }}</span> {{ $t('units.package') }}</h7>
          <h7>⚖️ &nbsp; <span class="text-primary pr-25">{{ numberFormat(element.total_weight) }}</span>
            {{ $t('units.kg') }}
          </h7>

        </div>

        <ProductionStatCard
          v-if="Object.keys(element.types).length>=1"
          class="mb-1 animate__animated animate__fadeInUp"
          :title="$t('therms.per-crop-types').toString()"
          :items="Object.values(element.types)"
          :max-quantity="element.total_weight"
          animated
        />

        <ProductionStatCard
          v-if="element.options.includes('packings') !== null && Object.keys(element.packings).length>=1"
          class="mb-1 animate__animated animate__fadeInUp"
          :title="$t('therms.per-crop-packings').toString()"
          :items="Object.values(element.packings)"
          :max-quantity="element.total_weight"
          variant="warning"
          animated
        />

        <ProductionStatCard
          v-if="element.options.includes('grades') !== null && Object.keys(element.grades).length>=1"
          class="mb-1 animate__animated animate__fadeInUp"
          :title="$t('therms.per-crop-grades').toString()"
          :items="Object.values(element.grades)"
          :max-quantity="element.total_weight"
          variant="danger"
          animated
        />

        <ProductionStatCard
          v-if="Object.keys(element.plots).length>=1"
          class="mb-1 animate__animated animate__fadeInUp"
          :title="$t('therms.per-plots').toString()"
          :items="Object.values(element.plots)"
          :max-quantity="element.total_weight"
          variant="info"
          animated
        />

        <ProductionStatCard
          v-if="Object.keys(element.accounts).length>=1"
          class="mb-1 animate__animated animate__fadeInUp"
          :title="$t('therms.per-accounts').toString()"
          :items="Object.values(element.accounts)"
          :max-quantity="element.total_weight"
          variant="danger"
          animated
        />
      </div>
    </template>
  </scroller>
</template>

<script>
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import ProductionStatCard from '@/views/components/ProductionStatCard.vue'
import { mapGetters } from 'vuex'
// eslint-disable-next-line import/no-cycle
import { NAME_NOW } from '@/router/routes/now'

export default {
  components: {
    NavButtonBar,
    NavButton,
    ProductionStatCard,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      list: 'shipmentsNow/getList',
      count: 'shipmentsNow/count',
      isFetch: 'shipmentsNow/isFetch',
      stats: 'shipmentsNow/getStats',
    }),
    element() {
      const item = Object.values(this.stats)
      // eslint-disable-next-line radix
        .filter(e => parseInt(e.id) === parseInt(this.$router.currentRoute.params.crop_id))

      if (item.length < 1) return null
      return item[0]
    },
  },
  mounted() {
    if (!this.$store.getters['shipmentsNow/isFetch']) {
      this.$router.replace({ name: NAME_NOW })
    }
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
